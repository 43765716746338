import React from 'react';

import './styles.css';


const NeonWrapper = ({ children, ...rest }) => (
  <div {...rest}>
    <div className="w-full h-full p-1 border-2 border-neon-blue shadow-neon-blue rounded-lg">
      <div className="w-full h-full p-1 border-4 border-neon-yellow shadow-neon-yellow rounded-lg">
        <div className="w-full h-full border-2 border-neon-pink shadow-neon-pink rounded-lg">
          {children}
        </div>
      </div>
    </div>
  </div>
);

export default NeonWrapper;
