import React from 'react';

import Message from 'containers/Message';

import './styles.css';


const Restart = ({ onConfirm, onCancel, ...props}) => {
  return (
    <Message className="text-center">
      <h3 className="font-display text-2xl text-neon-blue">End Game</h3>
      <h4 className="font-sans text-sm text-white mb-4">Are you sure?</h4>

      <button
        className="btn secondary mr-2"
        onClick={onConfirm}
      >
        Yes
      </button>
      <button
        className="btn pulse ml-2 "
        onClick={onCancel}
      >
        Cancel
      </button>
    </Message>
  );
};

export default Restart;
