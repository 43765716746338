import React from 'react';

import './styles.css';


const MediaPlayer = ({ muted, ...props }) => (
  <iframe
    className="media-player"
    title="MediaPlayer"
    width="560"
    height="315"
    src={`https://www.youtube.com/embed/videoseries?list=PLKL5gYv3FnD01vYWqtPrL_beBnxcdUzBp&autoplay=1&controls=0${muted ? '&mute=1' : ''}`}
    frameBorder="0"
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
    {...props}
  >
  </iframe>
);

export default MediaPlayer;
