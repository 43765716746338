import React, { useState } from 'react';
import { xor } from 'lodash';

import players from 'utils/players';

import './styles.css';


const PlayerChooser = ({ onStart, ...props }) => {
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [activePlayer, setActivePlayer] = useState('');
  const readyToStart = selectedPlayers.length >= 2;

  return (
    <div {...props}>
      <div className="mx-auto px-4 text-center" style={{ maxWidth: '820px' }}>
        {!activePlayer
          ? <h2 className={`${readyToStart ? '' : 'pulse'} font-display text-xl text-neon-blue`}>Select Players</h2>
          : <h3 className="font-display text-xl text-neon-pink">{`${players[activePlayer].firstName} ${players[activePlayer].lastName}`}</h3>
        }

        <div className="h-xl md:h-lg px-6 font-sans text-xs text-white">
          {activePlayer ? players[activePlayer].description : ''}
        </div>

        <div className="flex flex-wrap justify-center -mx-1 my-2">
          {Object.values(players).map((player) => (
            <div
              className="px-1 mb-4"
              key={player.id}
            >
              <button
                className={`
                  person rounded-full border-0
                  ${selectedPlayers.includes(player.id)
                    ? 'bg-neon-pink'
                    : 'bg-transparent hover:wiggle'
                  }
                `}
                onMouseEnter={() => setActivePlayer(player.id)}
                onMouseLeave={() => setActivePlayer('')}
                onClick={() => setSelectedPlayers((prevPlayers) => xor(prevPlayers, [player.id]))}
              >
                <img
                  src={`/images/players/smile/${player.id}.png`}
                  alt={player.name}
                  width="50px"
                  draggable={false}
                />
              </button>
            </div>
          ))}
        </div>

        <button
          className={`btn ${readyToStart ? 'pulse' : '' }`}
          disabled={!readyToStart}
          onClick={() => onStart(selectedPlayers)}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default PlayerChooser;
