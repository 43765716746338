import React, { useEffect } from 'react';

import allPlayers from 'utils/players';

import './styles.css';


const Players = ({ players, target, turn, onMoveDone, ...props}) => {
  const getPositionFromTile = (tile) => {
    const index = tile - 1;
    let row = Math.floor(index / 10 % 10);  // tens place
    let flipRow = row % 2 !== 0 ;
    let col = flipRow && tile !== 0
      ? 9 - Math.floor(index % 10)
      : Math.floor(index % 10); // ones place
    // Start the players off the board
    if (tile === 0) {
      row = 0;
      col = -2;
      flipRow = false;
    }
    return {
      bottom: `${(row * 10) + 5}%`,
      left: `${(col * 10) + 5}%`,
      transform: `
        scaleX(${flipRow ? -1 : 1})
        translate(${flipRow ? 50 : -50}%, 50%)
      `,
    }
  };

  useEffect(() => {
    const player = players[turn];
    if (target !== null && player.current !== Math.abs(target)) {
      if (target < 0) {
        // Move the player to the end of the feature
        setTimeout(() => {
          onMoveDone(-1 * target);
        }, 8000);
      } else if (target > 100) {
        // Don't move the player
        return;
      } else {
        // Move the player up one space
        setTimeout(() => {
          onMoveDone(player.current + 1);
        }, 750);
      }
    }
  }, [players, turn, target]);  // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="absolute inset-0">
      {players.map((player, i) => {
        const playerDetails = allPlayers[player.id];
        return (
          <div
            key={playerDetails.id}
            className={`board-player absolute ${i === turn ? 'z-50' : `z-${i}`}`}
            style={{ width: '10%', ...getPositionFromTile(player.current) }}
          >
            {i === turn &&
              <div className="board-player__highlight bounce"></div>
            }
            <img
              className={`rounded-full ${i === turn ? 'wiggle' : ''}`}
              src={`/images/players/walk/${playerDetails.id}.png`}
              alt={playerDetails.name}
              draggable={false}
            />
          </div>
        );
      })}
    </div>
  );
};

export default Players;
