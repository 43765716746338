import React from 'react';

import './styles.css';


const Title = props => (
  <div {...props}>
    <h1 className="pulse flex justify-center pt-6 pb-2 font-display">
      <span className="self-start mt-1 text-neon-yellow text-3xl">Shoots</span>
      <span className="self-center mx-2 text-neon-pink text-5xl">&</span>
      <span className="copyright self-end mb-1 text-neon-blue text-3xl">Vloggers</span>
    </h1>
  </div>
);

export default Title;
