const kingMoves = {
  0: 4,
  14: 6,
  20: 6,
  26: 2,
  84: 6,
  90: 6,
  96: 4,
};

const ladders = {
  1: 38,
  4: 14,
  9: 31,
  21: 42,
  28: 84,
  36: 44,
  51: 67,
  71: 91,
  80: 100,
};

const chutes = {
  16: 7,
  47: 26,
  49: 11,
  56: 53,
  62: 19,
  64: 60,
  87: 24,
  93: 73,
  95: 75,
  98: 78,
};

const ladderMessages = {
  1: 'You started a podcast with your best friend and it is BLOWING UP! Now just make it last more than 26 episodes. 👍',
  4: 'You collaborated on a makeup palette with Jeffree Star! 👍',
  9: 'After a wild night out on the town, you meet your hero, Miss Juicy Baby. 👍',
  21: 'SeatGeek, an app that helps you buy tickets in the easiest way possible, just bought your friend a brand new Ferrari! 👍',
  28: 'You went to Vegas and bet $25,000 on red. Double up! 👍',
  36: 'Your manager just informed you that a giant billboard with your face on it will be somewhere in LA. 👍',
  51: 'You just passed the lie detector test with flying colors. And now everyone knows you have a crush on Natalie. 👍',
  71: 'And the award for best Ensemble Cast goes to... Vlog Squad. 👍',
  80: '10 MILLION SUBS!!!!!!!!!! 👍',
};

const chutesMessages = {
  16: 'You\'ve been canceled! 👎',
  47: 'Uh oh! You just landed in a threesome with Durte Dom. 👎',
  49: 'You caught a glass bottle barehanded, it shattered, and you cut open your hand. AND YOU FORGOT TO GET THE THUMBNAIL. 👎',
  56: 'In exchange for $100 you now have three golf ball-sized wounds from David\'s paintball gun. Worth it? 👎',
  62: 'You ended up in the hospital after attempting to jump a moped across a pool. Ouch! 👎',
  64: 'Your assistant just got a baby blue Bronco and now is at the beach 24/7. 👎',
  87: 'Yikes! An old tweet has just popped up from 2009! 👎',
  93: 'David blindfolded you and unleashed 100 tarantulas on your stomach. 👎',
  95: 'A science experiment you ran in your living room went dangerously wrong and your ceiling now has a gaping hole. 👎',
  98: 'Nervous for your first time announcing, you accidentally call Kesha "Keesha" on-stage at the VMAs. Gasp! 👎',
};

const features = {
  ...ladders,
  ...chutes,
};

const messages = {
  ...ladderMessages,
  ...chutesMessages,
}

export {
  ladders,
  chutes,
  messages,
  ladderMessages,
  chutesMessages,
  kingMoves,
}
export default features;
