import React, { useEffect, useRef, useState } from 'react';
import ReactDice from 'react-dice-complete';

import allPlayers from 'utils/players';
import { kingMoves } from 'utils/features';

import 'react-dice-complete/dist/react-dice-complete.css';
import './styles.css';


const Turn = ({ state, players, target, turn, message, onRollDone, ...props }) => {
  const diceRef = useRef(null);
  const [disableForTurnNumber, setDisableForTurnNumber] = useState(-1);
  const rollDisabled = turn === disableForTurnNumber || target !== null || state !== 'game';

  useEffect(() => {
    const player = players[turn]
    const playerDetails = allPlayers[player.id];
    if (!rollDisabled) {
      if (playerDetails.role === 'king') {
        setDisableForTurnNumber(turn);
        const nextRoll = kingMoves[player.current];
        diceRef.current.rollAll([nextRoll]);  // pass the known next best roll
      } else if (playerDetails.role === 'computer') {
        setDisableForTurnNumber(turn);
        diceRef.current.rollAll();  // perform a random roll
      }
    }
  }, [players, turn, rollDisabled]);

  return (
    <div className="text-center" style={{ width: '330px' }}>
      <div className={`${rollDisabled ? 'cursor-not-allowed' : ''}`}>
        <div
          className={`dice relative inline-block ${rollDisabled ? 'pointer-events-none' : 'dice__glow cursor-pointer wiggle'}`}
          onClick={() => setDisableForTurnNumber(turn)}
        >
          <ReactDice
            disableIndividual={rollDisabled}
            numDice={1}
            dieSize={54}
            faceColor="#FFFFFF"
            dotColor="#000000"
            rollDone={(num) => {
              onRollDone(num);
            }}
            ref={dice => diceRef.current = dice}
          />
        </div>
      </div>

      <h3 className="font-display text-xl text-neon-pink">{allPlayers[players[turn].id].firstName}'s Turn</h3>

      <div className="flex-grow flex flex-wrap justify-center -mx-1 mb-2">
        {players.map((player, i) => {
          const playerDetails = allPlayers[player.id];
          return (
            <div
              className="px-1 mb-1"
              key={playerDetails.id}
            >
              <img
                className={`rounded-full ${i === turn ? 'bg-neon-pink' : ''}`}
                src={`/images/players/smile/${playerDetails.id}.png`}
                alt={playerDetails.name}
                width="40px"
                draggable={false}
              />
            </div>
          );
        })}
      </div>

      {message &&
        <div className="glow w-full py-1 px-2 font-sans text-xs text-white rounded-lg">
          {message}
        </div>
      }
    </div>
  );
};

export default Turn;
