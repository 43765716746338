import React from 'react';

import Message from 'containers/Message';
import Button from 'components/Button';

import './styles.css';


const Instructions = ({ onDone, ...props}) => {
  return (
    <Message withDonationPrompt className="text-center">
      <h3 className="font-display text-2xl text-neon-blue">How to Play</h3>

      <p className="font-sans text-sm text-white mb-4">
        Take turns rolling the dice to see who can reach the end of the board first.
        Land on a <span className="font-display tracking-wider text-neon-yellow">Lamborghini Ladder</span> and go <span className="font-display tracking-wider text-neon-yellow">up</span>!
        Land on a <span className="font-display tracking-wider text-neon-pink">Canceled Carpet</span> and go <span className="font-display tracking-wider text-neon-pink">down</span>.
      </p>

      <Button
        type="play"
        size="small"
        onClick={onDone}
      />
    </Message>
  );
};

export default Instructions;
