import React from 'react';

import Message from 'containers/Message';

import allPlayers from 'utils/players';

import './styles.css';


const Winner = ({ players, turn, onPlayAgain, ...props}) => {
  const playerDetails = allPlayers[players[turn].id];

  return (
    <Message className="text-center">
      <h3 className="font-display text-2xl text-neon-blue">{playerDetails.firstName} Wins!</h3>
      {playerDetails.role === 'king' &&
        <h4 className="mb-1 font-sans text-xxxs text-white">(But he always does in this game.)</h4>
      }
      <img
        className="wiggle person mb-4 mx-auto"
        src={`/images/players/win/${playerDetails.id}.png`}
        alt={playerDetails.name}
        width="100px"
        draggable={false}
      />
      <button
        className="btn pulse"
        onClick={onPlayAgain}
      >
        Play Again
      </button>
    </Message>
  );
};

export default Winner;
