import React from 'react';

import NeonWrapper from 'containers/NeonWrapper';
import Players from 'containers/Players';
import Tile from 'components/Tile';

import './styles.css';


const Board = props => {
  const tiles = [];

  for (let row = 1; row <= 10; row++) {
    for (let col = 1; col <= 10; col++) {
      const x = row % 2 === 0 ? 11 - col : col;
      const index = ((row - 1) * 10) + x;
      tiles.push(<Tile key={index} index={index} />);
    }
  }

  return (
    <NeonWrapper className="board bg-black">
      <div className="relative flex flex-wrap-reverse h-full">
        {tiles}
        <img
          className="absolute inset-0"
          src="/images/features.png"
          alt="features"
          draggable={false}
        />
        <Players {...props} />
      </div>
    </NeonWrapper>
  );
};

export default Board;
