const players = {
  'alex': {
    id: 'alex',
    firstName: 'Alex',
    lastName: 'Ernst',
    description: 'Stomping through white IKEA game board tiles.',
    role: 'player',
  },
  'bignik': {
    id: 'bignik',
    firstName: 'BigNik',
    lastName: '',
    description: 'If he loses this game, David will make fun of him. For being short though, not for losing.',
    role: 'player',
  },
  'brandon': {
    id: 'brandon',
    firstName: 'Brandon',
    lastName: 'Calvillo',
    description: '"Alex, what does this T stand for? Titties?"',
    role: 'player',
  },
  'carly': {
    id: 'carly',
    firstName: 'Carly',
    lastName: 'Incontro',
    description: 'Good chance at winning the game unless she stops for Starbucks in the middle.',
    role: 'player',
  },
  'corinna': {
    id: 'corinna',
    firstName: 'Corinna',
    lastName: 'Kopf',
    description: 'Cruising through the board almost as fast as her 718.',
    role: 'player',
  },
  'david': {
    id: 'david',
    firstName: 'David',
    lastName: 'Dobrik',
    description: 'Sponsored by SeatGeek, an app that helps you buy tickets in literally the easiest way possible.',
    role: 'player',
  },
  'dom': {
    id: 'dom',
    firstName: 'Durte Dom',
    lastName: 'Zeglaitis',
    description: 'Doesn\'t play board games, just spits game.',
    role: 'player',
  },
  'erin': {
    id: 'erin',
    firstName: 'Erin',
    lastName: 'Gilfoy',
    description: 'Maybe a "mental breakdown" according to Google Images, but not here!',
    role: 'player',
  },
  'heath': {
    id: 'heath',
    firstName: 'Heath',
    lastName: 'Hussar',
    description: 'Winning the game and stealing the show, like someone stole Big Red.',
    role: 'player',
  },
  'jason': {
    id: 'jason',
    firstName: 'Jason',
    lastName: 'Nash',
    description: 'May not have youth or speed on his side, but neither did the tortoise when he won the race.',
    role: 'player',
  },
  'jeff': {
    id: 'jeff',
    firstName: 'Jeff',
    lastName: 'Wittek',
    description: 'Got his start playing Chutes & Ladders.',
    role: 'player',
  },
  'joe': {
    id: 'joe',
    firstName: 'Joe',
    lastName: 'Vulpis',
    description: 'He\'s probably already thinking about ways to make a 30 second game inside of this one.',
    role: 'player',
  },
  'jonah': {
    id: 'jonah',
    firstName: 'Nick',
    lastName: '(Jonah) Antonyan',
    description: 'Not yet featured in a major comedy movie, but at least now he\'s a character in a board game.',
    role: 'player',
  },
  'josh': {
    id: 'josh',
    firstName: 'Josh',
    lastName: 'Peck',
    description: 'Hug me brother.',
    role: 'player',
  },
  'kristen': {
    id: 'kristen',
    firstName: 'Kristen',
    lastName: 'McAtee',
    description: 'COOKING! up a Shoots and Vloggers win.',
    role: 'player',
  },
  'liza': {
    id: 'liza',
    firstName: 'Liza',
    lastName: 'Koshy',
    description: 'Helga\'s never seen a board game in her country.',
    role: 'player',
  },
  'mariah': {
    id: 'mariah',
    firstName: 'Mariah',
    lastName: 'Amato',
    description: 'She can dance, she can sing, she can win this game. Is there anything she can\'t do?',
    role: 'player',
  },
  'matt': {
    id: 'matt',
    firstName: 'Matt',
    lastName: 'King',
    description: 'Trying to use his smarts and college degree to win a game of chance and luck.',
    role: 'player',
  },
  'natalie': {
    id: 'natalie',
    firstName: 'Natalie',
    lastName: 'Mariduena',
    description: 'Get out the box of Nut-Thins for this one.',
    role: 'player',
  },
  'scotty': {
    id: 'scotty',
    firstName: 'Scotty',
    lastName: 'Sire',
    description: 'Getting noticed for his talent at Shoots & Vloggers.',
    role: 'player',
  },
  'suzy': {
    id: 'suzy',
    firstName: 'Suzy',
    lastName: 'Antonyan',
    description: 'Plays like Courtney Cox is waiting at the finish line.',
    role: 'player',
  },
  'todd': {
    id: 'todd',
    firstName: 'Todd',
    lastName: 'Smith',
    description: 'Tiles black, tiles white. Moving up ladders, future so bright. Fin.',
    role: 'player',
  },
  'vardan': {
    id: 'vardan',
    firstName: 'Vardan',
    lastName: 'Antonyan',
    description: 'May be invisible sometimes, but not this time.',
    role: 'player',
  },
  'zane': {
    id: 'zane',
    firstName: 'Zane',
    lastName: 'Hijazi',
    description: 'Play as Zane and you\'re really secretly playing as Miss Juicy, baby.',
    role: 'player',
  },
  'king': {
    id: 'king',
    firstName: 'King David',
    lastName: '',
    description: '"King David" has perfect luck and will never lose this game. Basically David in real life.',
    role: 'king',
  },
  'computer': {
    id: 'computer',
    firstName: 'Computer',
    lastName: '',
    description: 'Play against an automated opponent who rolls randomly.',
    role: 'computer',
  },
};

export default players;
