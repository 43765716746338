import React from 'react';

import NeonWrapper from 'containers/NeonWrapper';

import './styles.css';


const Message = ({ children, withDonationPrompt, ...rest }) => (
  <React.Fragment>
    <div className="absolute z-100 inset-0 p-4 bg-black-transparent flex items-center">
      <NeonWrapper
        className="scale mx-auto bg-black"
        style={{ maxHeight: '500px',  maxWidth: '500px' }}
      >
        <div className="py-4 px-12">
          <div {...rest}>
            {children}
          </div>
        </div>
      </NeonWrapper>
    </div>

    {withDonationPrompt &&
      <p className="absolute z-100 w-full bottom-0 p-8 font-sans text-white text-xs text-center">A real human took real time to make this game. There are no ads or brand deals. Please consider <a className="border-dashed border-b border-white" href="https://www.buymeacoffee.com/shootsandvloggers" target="__blank">buying me a coffee</a>. 🙏</p>
    }
  </React.Fragment>
);

export default Message;
