import React from 'react';

import './styles.css';


const Tile = ({ index, ...props }) => {
  if (index === 100) {
    return (
      <div className={`tile flex flex-wrap rounded-tl-lg`}>
        {[...Array(25)].map((e, i) => (
          <div key={i} className={`
            tile flex flex-auto
            ${ i % 2 === 0 ? 'bg-black' : 'bg-white'}
            ${ i === 0 ? 'rounded-tl-lg' : ''}
          `}></div>
        ))}
      </div>
    );
  }

  return (
    <div className={`
      tile flex flex-auto items-center justify-center font-display text-xs
      ${ index % 2 === 0 ? 'bg-black text-white' : 'bg-white text-black'}
      ${ index === 1 ? 'rounded-bl-lg' : ''}
      ${ index === 10 ? 'rounded-br-lg' : ''}
      ${ index === 91 ? 'rounded-tr-lg' : ''}
    `}>
      {index}
    </div>
  );

};

export default Tile;
