import React, { useEffect } from 'react';

import Board from 'containers/Board';
import Turn from 'containers/Turn';
import Winner from 'containers/Winner';
import Instructions from 'containers/Instructions';
import Restart from 'containers/Restart';
import Buttons from 'containers/Buttons';
import Title from 'components/Title';
import Button from 'components/Button';
import PlayerChooser from 'components/PlayerChooser';
import MediaPlayer from 'components/MediaPlayer';

import { getFromLocalStorage, saveToLocalStorage, saveAllToLocalStorage } from 'utils/storage';
import { useStateAsync } from 'utils/hooks';
import features, { messages } from 'utils/features';

import './styles.css';


const App = props => {
  // The game states are:
  // `start`: waiting for the user to press the start button
  // `choosePlayers`: waiting for the user to finish choosing players
  // `game`: the game is underway and some `turn` value will be set
  // `finished`: the game has ended and we await the user beginning a new game
  // `help`: the game is paused with instructions being shown
  // `restart`: the game is paused with confirmation to restart
  const localStorageDefaults = {
    state: 'start',
    players: [],
    target: null,
    turn: 0,
    muted: true,
  };

  const [state, setState] = useStateAsync(getFromLocalStorage('state', localStorageDefaults.state));
  const [players, setPlayers] = useStateAsync(getFromLocalStorage('players', localStorageDefaults.players));
  const [target, setTarget] = useStateAsync(getFromLocalStorage('target', localStorageDefaults.target));
  const [turn, setTurn] = useStateAsync(getFromLocalStorage('turn', localStorageDefaults.turn));
  const [muted, setMuted] = useStateAsync(getFromLocalStorage('muted', localStorageDefaults.muted));
  const [message, setMessage] = useStateAsync('');

  const advanceTurn = (delay = 1500) => {
    const newTurn = (turn + 1) % players.length;

    // Immediately save the new turn and target to local storage in
    // case the user refreshes the page. But if they don't, there
    // will still be a nice delay between turns.
    setTimeout(() => {
      saveToLocalStorage('target', null);
      saveToLocalStorage('turn', newTurn);
    }, 1);

    setTimeout(() => {
      setTarget(null).then(() => {
        setTurn(newTurn);
      });
    }, delay);
  };

  const showMessage = (message, delay = 5000) => {
    setMessage(message).then(() => {
      setTimeout(() => {
        setMessage('');
      }, delay);
    });
  };

  const reset = () => {
    saveAllToLocalStorage(localStorageDefaults);
    window.location.reload(false);
  };

  useEffect(() => {
    saveAllToLocalStorage({
      state,
      players,
      target,
      turn,
      muted,
    });
  }, [state, players, target, turn, muted]);

  return (
    <div className="h-screen overflow-x-hidden overflow-y-scroll bg-repeat" style={{ backgroundImage: 'url("/images/backgrounds/memphis-mini-dark.png")' }}>
      <div className="scale mx-auto">
        {/* Title area */}
        <Title />

        {/* Start new game area */}
        {state === 'start' &&
          <React.Fragment>
            <h1 className="text-center text-xxs text-white font-sans">A Vlog Squad rendition of the classic board game <i>Chutes and Ladders</i>.</h1>
            <Button
              type="play"
              size="lg"
              className="mt-20 text-center"
              onClick={() => setState('choosePlayers')}
            />
          </React.Fragment>
        }

        {/* Choose players area */}
        {state === 'choosePlayers' &&
          <PlayerChooser
            className="mt-6"
            onStart={(players) => {
              setPlayers(players.map((player) => ({
                id: player,
                current: 0,
              }))).then(() => setState('help'));
            }}
          />
        }

        {/* Game play area */}
        <div className="flex flex-wrap justify-center items-stretch select-none">
          {(state === 'game'
            || state === 'finished'
            || state === 'help'
            || state === 'restart') && players.length > 0 &&
            <div className="flex flex-wrap justify-center items-stretch select-none">
              <div className="px-6 my-2">
                <Board
                  players={players}
                  target={target}
                  turn={turn}
                  onMoveDone={(newPosition) => {
                    // Update the player's position
                    setPlayers(() => {
                      const newPlayers = [...players];
                      newPlayers[turn].current = newPosition;
                      return newPlayers;
                    }).then(() => {
                      const player = players[turn];
                      if (player.current === Math.abs(target)) {
                        // Check if the user won
                        if (player.current === 100) {
                          setState('finished');
                          return;
                        }
                        // Check if they've landed on a feature and if so,
                        // set the new target to -1 * that feature location
                        // so we know to jump directly to it, instead of
                        // incrementing as normal
                        const featureResult = features[player.current];
                        if (featureResult) {
                          showMessage(messages[player.current], 8000);
                          setTarget(featureResult * -1);
                          return;
                        }
                        // Otherwise, wait some time and then end their turn
                        advanceTurn(1500);
                      }
                    });
                  }}
                />
              </div>
              <div className="px-6 my-2">
                <Turn
                  state={state}
                  players={players}
                  turn={turn}
                  target={target}
                  message={message}
                  onRollDone={(num) => {
                    const newTarget = players[turn].current + num;
                    setTarget(newTarget);
                    // Must land exactly on 100 and avoid going over
                    if (newTarget > 100) {
                      showMessage('Bust! Must land exactly on 100.', 4000);
                      advanceTurn(4000);
                      return;
                    }
                  }}
                />
              </div>
            </div>
          }
        </div>
      </div>

      {state === 'finished' &&
        <Winner
          players={players}
          turn={turn}
          onPlayAgain={reset}
        />
      }

      {state === 'help' &&
        <Instructions
          onDone={() => {
            if (players.length > 0) {
              setState('game');
            } else {
              setState('start');
            }
          }}
        />
      }

      {state === 'restart' &&
        <Restart
          onCancel={() => setState('game')}
          onConfirm={() => reset()}
        />
      }

      <Buttons
        state={state}
        muted={muted}
        onMute={() => setMuted(!muted)}
        onHelp={() => setState('help')}
        onEnd={() => setState('restart')}
      />

      <MediaPlayer muted={muted} />
    </div>
  );
};

export default App;
