const getFromLocalStorage = (key, def = '') => {
  const item = localStorage.getItem(key);
  if (item !== null) {
    return JSON.parse(item);
  }
  return def;
};

const saveToLocalStorage = (key, val) => {
  localStorage.setItem(key, JSON.stringify(val));
}

const saveAllToLocalStorage = (obj) => {
  for (const [key, val] of Object.entries(obj)) {
    saveToLocalStorage(key, val);
  }
}

export {
  getFromLocalStorage,
  saveToLocalStorage,
  saveAllToLocalStorage,
};
