import React from 'react';

import Button from 'components/Button';

import './styles.css';


const Buttons = ({ state, muted, onMute, onHelp, onEnd, ...props }) => {
  return (
    <div className="controls scale fixed bottom-1 right-1">
      <Button
        type="sound"
        size="sm"
        skin={muted ? 'secondary' : 'primary'}
        pulse={false}
        onClick={onMute}
        className="mt-2"
      />

      {state === 'game' &&
        <Button
          type="help"
          size="sm"
          pulse={false}
          onClick={onHelp}
          className="mt-2"
        />
      }

      {state === 'game' &&
        <Button
          type="end"
          size="sm"
          pulse={false}
          onClick={onEnd}
          className="mt-2"
        />
      }
    </div>
  );
};

export default Buttons;
